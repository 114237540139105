import React from "react"
import { graphql, StaticQuery } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
// import { NONAME } from "dns"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 class="post-content-excerpt">
            Howdy! I'm Jared. Software engineer, designer, and strategist with
            20 years of production management expertise&mdash;some call me a
            <strong> Swiss Army Knife</strong> (but I'm from Texas).
          </h2>
          <div>
            <p>
              I focus on user-centered applications for people, planet, and
              profit. Currently I'm an Instructional Associate for General
              Assembly's Software Engineering Immersive Remote. I love to
              collaborate with founders, developers, designers, and artist on
              apps, films, print, experiences, and environments.
            </p>
            <p>
              In my spare time I write and paint in the SF Bay Area with my dog,
              Rosa.
            </p>
            <p>Drop us a line. </p>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
